import { IUser, User } from '../../models/user';

/**
 * Authentication response object.
 */
export class AuthResponse {
    user: IUser;

    static toUser(response: AuthResponse): User {
        const user = new User();
        user.email = response.user.email;
        user.displayName = response.user.displayName;
        user.modules = response.user.modules;
        user.role = response.user.role;
        return user;
    }
}

export type TLoginResponse = {
    token: string;
};
