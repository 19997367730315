import { Roles } from "../../shared/types/permissions.types";

export interface UserRaw {
    email: string;
    displayName: string;
    modules: string[];
    readOnly?: boolean;
}

export interface IUser {
    email: string;
    displayName: string;
    modules: string[];
    role: string;
}

export class User implements IUser {
    email: string;
    displayName: string;
    modules: string[];
    role: string;

    static fromRaw(rawData: UserRaw): IUser {
        return {
            email: rawData.email,
            displayName: rawData.displayName,
            modules: rawData.modules,
            role: rawData.readOnly ? Roles.USER : Roles.ADMINISTRATOR,
        };
    }
}
